import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUser } from '../../store/slices/authSlice';
import { Toast } from '../common/Toast';
import { userApi } from '../../services/api';

interface UsernameModalProps {
  isOpen: boolean;
  address: string;
  onComplete: () => void;
}

export const UsernameModal: React.FC<UsernameModalProps> = ({
  isOpen,
  address,
  onComplete,
}) => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>('');

  const validateUsername = (value: string): boolean => {
    if (!value.trim()) {
      setError('Username is required');
      return false;
    }

    if (!/^[a-zA-Z0-9_]{3,20}$/.test(value)) {
      setError('Username must be 3-20 characters and contain only letters, numbers and underscores');
      return false;
    }

    setError('');
    return true;
  };

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setUsername(value);
    if (value) {
      validateUsername(value);
    } else {
      setError('');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateUsername(username)) {
      return;
    }

    try {
      setIsLoading(true);
      const loadingToast = Toast.loading('Setting up your account...');
      
      const { user } = await userApi.updateProfile({ username });
      dispatch(setUser(user));
      
      Toast.success('Account created successfully!', { id: loadingToast });
      onComplete();
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Failed to create account';
      Toast.error(message);
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-black/90 p-8 rounded-lg border border-white/10 w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6">Choose your username</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-6">
            <label htmlFor="username" className="block text-sm font-medium text-white/60 mb-2">
              Username
            </label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={handleUsernameChange}
              className={`w-full px-4 py-2 bg-white/5 border ${
                error ? 'border-red-500' : 'border-white/10'
              } rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent`}
              placeholder="Enter your username"
              disabled={isLoading}
            />
            {error && (
              <p className="mt-1 text-sm text-red-500">{error}</p>
            )}
            <p className="mt-2 text-sm text-white/60">
              Your username will be used to identify you on the platform.
            </p>
          </div>
          <button
            type="submit"
            disabled={isLoading || !username.trim() || !!error}
            className="w-full px-4 py-2 bg-white text-black font-medium rounded-lg hover:bg-white/90 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isLoading ? 'Creating...' : 'Create Account'}
          </button>
        </form>
      </div>
    </div>
  );
}; 