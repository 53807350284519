import React, { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { authService } from '../../services/auth';
import { emailAuthService } from '../../services/emailAuth';
import { setUser, setLoading, setError } from '../../store/slices/authSlice';
import { Toast } from '../common/Toast';
import { authApi } from '../../services/api';
import { AppDispatch } from '../../store';

interface AuthMethodModalProps {
  isOpen: boolean;
  onClose: () => void;
  onNewUser?: (address: string) => void;
}

export const AuthMethodModal: React.FC<AuthMethodModalProps> = ({
  isOpen,
  onClose,
  onNewUser
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const modalRef = useRef<HTMLDivElement>(null);
  const [selectedMethod, setSelectedMethod] = React.useState<'email' | 'wallet' | null>(null);
  const [email, setEmail] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [localError, setLocalError] = React.useState<string | null>(null);
  const [magicLinkSent, setMagicLinkSent] = React.useState(false);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      onClose();
    }
  }, [onClose]);

  React.useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, handleClickOutside]);

  if (!isOpen) return null;

  const handleWalletConnect = async () => {
    let loadingToast: string | undefined;
    
    try {
      setIsLoading(true);
      dispatch(setLoading(true));
      dispatch(setError(null));

      // Connect wallet
      loadingToast = Toast.loading('Connecting wallet...');
      const address = await authService.connectWallet();
      
      // Get challenge
      Toast.loading('Getting challenge...', { id: loadingToast });
      const { challenge } = await authApi.getChallenge();
      
      // Sign challenge
      Toast.loading('Please sign the message to verify your wallet ownership. This is a one-time authentication step and cannot access your funds.', { id: loadingToast });
      const { signature } = await authService.signMessage(challenge);
      
      // Verify signature
      Toast.loading('Verifying signature...', { id: loadingToast });
      const { session_token, user: userData } = await authApi.verifySignature(address, signature, challenge);
      
      // Save token BEFORE any other operations
      localStorage.setItem('session_token', session_token);
      
      if (!userData.username) {
        if (onNewUser) {
          onNewUser(address);
        }
        Toast.success('Wallet connected! Please set your username.', { id: loadingToast });
      } else {
        dispatch(setUser(userData));
        Toast.success('Connected successfully!', { id: loadingToast });
        onClose();
      }
    } catch (error) {
      console.error('Failed to connect wallet:', error);
      const message = error instanceof Error ? error.message : 'Failed to connect wallet';
      if (loadingToast) {
        Toast.error(message, { id: loadingToast });
      } else {
        Toast.error(message);
      }
    } finally {
      setIsLoading(false);
      dispatch(setLoading(false));
    }
  };

  const handleEmailSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email.trim()) return;

    setIsLoading(true);
    setLocalError(null);
    
    try {
      await emailAuthService.sendMagicLink(email);
      setMagicLinkSent(true);
      Toast.success('Magic link sent! Check your email');
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Failed to send magic link';
      setLocalError(message);
      Toast.error(message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center z-50">
      <div ref={modalRef} className="bg-black/90 p-8 rounded-lg border border-white/10 w-full max-w-md relative">
        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-white/60 hover:text-white transition-colors"
          aria-label="Close modal"
        >
          <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

        <h2 className="text-2xl font-bold mb-6">Connect to degs</h2>
        
        {!selectedMethod ? (
          <div className="space-y-4">
            <button
              onClick={handleWalletConnect}
              disabled={isLoading}
              className="w-full px-4 py-3 bg-emerald-500 text-white font-medium rounded-lg hover:bg-emerald-600 transition-colors flex items-center justify-center space-x-2 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isLoading ? (
                <div className="w-5 h-5 border-2 border-white/20 border-t-white rounded-full animate-spin" />
              ) : (
                <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
                </svg>
              )}
              <span>{isLoading ? 'Connecting...' : 'Connect with MetaMask'}</span>
            </button>
            
            <button
              onClick={() => {
                setSelectedMethod('email');
                dispatch(setError(null));
              }}
              disabled={isLoading}
              className="w-full px-4 py-3 bg-white/10 text-white font-medium rounded-lg hover:bg-white/20 transition-colors flex items-center justify-center space-x-2 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
              </svg>
              <span>Continue with Email</span>
            </button>
          </div>
        ) : (
          <div className="space-y-4">
            {magicLinkSent ? (
              <div className="text-center space-y-4">
                <div className="text-lg text-white/80">Check your email</div>
                <p className="text-white/60">
                  We've sent a magic link to <span className="text-white">{email}</span>
                </p>
                <p className="text-sm text-white/40">
                  Click the link in your email to sign in
                </p>
                <button
                  type="button"
                  onClick={() => {
                    setSelectedMethod(null);
                    setMagicLinkSent(false);
                    setEmail('');
                  }}
                  className="text-sm text-white/60 hover:text-white"
                >
                  ← Back to options
                </button>
              </div>
            ) : (
              <form onSubmit={handleEmailSubmit} className="space-y-4">
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-white/60 mb-2">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-transparent"
                    placeholder="Enter your email"
                    disabled={isLoading}
                  />
                </div>

                {localError && (
                  <div className="p-4 bg-red-500/10 border border-red-500/20 rounded-lg text-red-400 text-sm">
                    {localError}
                  </div>
                )}

                <div className="flex flex-col space-y-2">
                  <button
                    type="submit"
                    disabled={isLoading || !email.trim()}
                    className="w-full px-4 py-2 bg-emerald-500 text-white font-medium rounded-lg hover:bg-emerald-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {isLoading ? 'Sending...' : 'Send Magic Link'}
                  </button>
                  
                  <button
                    type="button"
                    onClick={() => setSelectedMethod(null)}
                    className="text-sm text-white/60 hover:text-white"
                  >
                    ← Back to options
                  </button>
                </div>
              </form>
            )}
          </div>
        )}
      </div>
    </div>
  );
}; 