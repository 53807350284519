import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUser, setLoading, setError } from '../store/slices/authSlice';
import { Toast } from '../components/common/Toast';
import { authApi } from '../services/api';
import { UsernameModal } from '../components/auth/UsernameModal';

export const AuthCallbackPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showUsernameModal, setShowUsernameModal] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);

  useEffect(() => {
    const handleCallback = async () => {
      // Previene chiamate multiple
      if (isVerifying) return;
      setIsVerifying(true);

      const magicLinkToken = searchParams.get('token');
      
      if (!magicLinkToken) {
        Toast.error('Invalid authentication link');
        navigate('/');
        return;
      }

      let loadingToast: string | undefined;

      try {
        dispatch(setLoading(true));
        dispatch(setError(null));

        // Verifica il token
        loadingToast = Toast.loading('Verifying your authentication...');
        const { user, session_token } = await authApi.verifyMagicLink(magicLinkToken);
        
        // Salva il session token PRIMA di qualsiasi altra operazione
        localStorage.setItem('session_token', session_token);
        
        if (!user.username) {
          // Se non ha username, mostra la modal
          setShowUsernameModal(true);
          Toast.success('Successfully logged in! Please set your username.', { id: loadingToast });
        } else {
          // Se ha già username, imposta l'utente e vai alla home
          dispatch(setUser(user));
          Toast.success('Successfully logged in!', { id: loadingToast });
          navigate('/');
        }
      } catch (error) {
        console.error('Failed to verify magic link:', error);
        const message = error instanceof Error ? error.message : 'Failed to verify authentication link';
        dispatch(setError(message));
        if (loadingToast) {
          Toast.error(message, { id: loadingToast });
        } else {
          Toast.error(message);
        }
        navigate('/');
      } finally {
        dispatch(setLoading(false));
      }
    };

    handleCallback();
  }, [searchParams, navigate, dispatch, isVerifying]);

  const handleUsernameSet = () => {
    setShowUsernameModal(false);
    navigate('/');
  };

  return (
    <>
      <div className="min-h-screen bg-black text-white flex items-center justify-center">
        <div className="text-center">
          <div className="w-16 h-16 border-4 border-emerald-500/20 border-t-emerald-500 rounded-full animate-spin mx-auto mb-4" />
          <h1 className="text-2xl font-bold mb-2">Logging you in...</h1>
          <p className="text-white/60">Please wait while we verify your authentication.</p>
        </div>
      </div>

      {showUsernameModal && (
        <UsernameModal
          isOpen={true}
          address=""
          onComplete={handleUsernameSet}
        />
      )}
    </>
  );
}; 