import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { GenerationModal } from './GenerationModal';
import { AuthMethodModal } from '../auth/AuthMethodModal';
import { Toast } from '../../components/common/Toast';
import { gamesApi } from '../../services/api';
import { FirstPublishModal } from './FirstPublishModal';

interface GameNavbarProps {
  kv_key: string;
}

interface GameDetails {
  id: number;
  kv_key: string;
  name: string;
  author_id: number;
  author_username: string;
  is_public: boolean;
  version_number: number;
  variant_index: number;
  created_at: string;
  likes_count: number;
  views_count: number;
  is_author: boolean;
  is_liked: boolean;
  public_version_key: string | false;
}

export const GameNavbar: React.FC<GameNavbarProps> = ({ kv_key }) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const [showGenerationModal, setShowGenerationModal] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showFirstPublishModal, setShowFirstPublishModal] = useState(false);
  const [game, setGame] = useState<GameDetails | null>(null);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isLiking, setIsLiking] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchGame = async () => {
      try {
        setIsLoading(true);
        const { game: gameDetails } = await gamesApi.getGame(kv_key);
        setGame(gameDetails);
      } catch (error) {
        console.error('Failed to load game info:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchGame();
  }, [kv_key]);

  useEffect(() => {
    if (isAuthenticated && showAuthModal) {
      setShowAuthModal(false);
      setShowGenerationModal(true);
    }
  }, [isAuthenticated, showAuthModal]);

  const handlePublishToggle = async () => {
    if (!game || !game.is_author || isPublishing) return;

    try {
      setIsPublishing(true);
      const isGamePage = window.location.pathname.startsWith('/g/');
      
      // Logica separata per /g/
      if (isGamePage) {
        if (!game.public_version_key) return; // Non dovrebbe mai succedere in /g/
        const response = await gamesApi.publish(game.public_version_key);
        if (response.success) {
          setGame(prev => prev ? { 
            ...prev, 
            public_version_key: response.is_public ? game.public_version_key : false 
          } : null);
          Toast.success(response.is_public ? 'Game published' : 'Game hidden');
          // Se abbiamo nascosto il gioco in /g/, redirect alla home
          if (!response.is_public) {
            navigate('/');
          }
        }
      } else {
        // Logica per /v/
        const response = await gamesApi.publish(kv_key);
        if (response.success) {
          if (response.is_first_publish) {
            setShowFirstPublishModal(true);
          } else {
            setGame(prev => prev ? { ...prev, is_public: response.is_public } : null);
            Toast.success(response.is_public ? 'Game published' : 'Game hidden');
          }
        }
      }
    } catch (error) {
      Toast.error('Failed to update game status');
    } finally {
      setIsPublishing(false);
    }
  };

  const handleImprove = () => {
    if (isAuthenticated) {
      setShowGenerationModal(true);
    } else {
      setShowAuthModal(true);
    }
  };

  const handleShare = () => {
    const url = window.location.href;
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile && navigator.share) {
      navigator.share({
        title: game?.name || 'Check out this game',
        text: 'Check out this game on degs',
        url
      });
    } else {
      navigator.clipboard.writeText(url);
      Toast.success('Link copied to clipboard');
    }
  };

  const handleLike = async () => {
    if (!isAuthenticated) {
      setShowAuthModal(true);
      return;
    }

    if (!game || isLiking) return;

    try {
      setIsLiking(true);
      const wasLiked = game.is_liked;
      
      // Optimistically update the UI
      setGame(prev => prev ? {
        ...prev,
        is_liked: !wasLiked,
        likes_count: prev.likes_count + (wasLiked ? -1 : 1)
      } : null);

      const { success } = await gamesApi.like(kv_key);
      
      if (!success) {
        // Revert changes if the request failed
        setGame(prev => prev ? {
          ...prev,
          is_liked: wasLiked,
          likes_count: prev.likes_count + (wasLiked ? 1 : -1)
        } : null);
        Toast.error('Failed to update like status');
      }
    } catch (error) {
      Toast.error('Failed to update like status');
    } finally {
      setIsLiking(false);
    }
  };

  return (
    <>
      <nav className="fixed w-full z-50 top-0 left-0 px-6 py-2 sm:py-4 bg-black/80 backdrop-blur-sm border-b border-white/10">
        <div className="max-w-7xl mx-auto flex items-center">
          {/* Left side */}
          <div className="flex-1">
            {game?.is_author && (
              <button
                onClick={handlePublishToggle}
                disabled={isPublishing}
                className={`px-3 py-1.5 text-sm font-medium rounded-lg transition-colors border disabled:opacity-50 disabled:cursor-not-allowed ${
                  window.location.pathname.startsWith('/g/') 
                    ? (game.public_version_key
                        ? 'bg-rose-500/20 text-rose-500 border-rose-500/20 hover:bg-rose-500/30'
                        : 'bg-emerald-500/20 text-emerald-500 border-emerald-500/20 hover:bg-emerald-500/30')
                    : (game.is_public 
                        ? 'bg-rose-500/20 text-rose-500 border-rose-500/20 hover:bg-rose-500/30' 
                        : 'bg-emerald-500/20 text-emerald-500 border-emerald-500/20 hover:bg-emerald-500/30')
                }`}
              >
                {isPublishing ? 'updating...' : (
                  window.location.pathname.startsWith('/g/') 
                    ? (game.public_version_key ? 'hide' : 'publish')
                    : (game.is_public ? 'hide' : 'publish')
                )}
              </button>
            )}
          </div>

          {/* Center */}
          <div className="flex-1 flex justify-center">
            <button
              onClick={handleImprove}
              className="px-4 py-2 bg-white text-black font-medium rounded-lg hover:bg-white/90 transition-colors"
            >
              improve
            </button>
          </div>

          {/* Right side */}
          <div className="flex-1 flex items-center justify-end space-x-4">
            <Link to="/" className="text-white/60 hover:text-white transition-colors">
              <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
              </svg>
            </Link>
            <button
              onClick={(e) => {
                e.stopPropagation();
                if (!isAuthenticated) {
                  setShowAuthModal(true);
                  return;
                }
                handleLike();
              }}
              disabled={isLiking || isLoading}
              className={`transition-colors ${
                game?.is_liked
                  ? 'text-red-500 hover:text-red-600'
                  : 'text-white/60 hover:text-white'
              } ${(isLiking || isLoading) ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              {isLiking || isLoading ? (
                <div className="w-6 h-6 border-2 border-current rounded-full animate-spin border-b-transparent" />
              ) : (
                <svg 
                  className="w-6 h-6" 
                  fill={game?.is_liked ? "currentColor" : "none"} 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" 
                  />
                </svg>
              )}
            </button>
            <button
              className="text-white/60 hover:text-white transition-colors"
              onClick={handleShare}
            >
              <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
              </svg>
            </button>
          </div>
        </div>
      </nav>

      <AuthMethodModal
        isOpen={showAuthModal}
        onClose={() => setShowAuthModal(false)}
        onNewUser={() => {
          setShowAuthModal(false);
          setShowGenerationModal(true);
        }}
      />

      {showGenerationModal && (
        <GenerationModal
          isOpen={showGenerationModal}
          onClose={() => setShowGenerationModal(false)}
          parentGame={{ kv_key }}
        />
      )}

      <FirstPublishModal
        isOpen={showFirstPublishModal}
        onClose={() => setShowFirstPublishModal(false)}
      />
    </>
  );
}; 