import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { GameNavbar } from '../components/games/GameNavbar';
import { API_URL } from '../services/api';

export const VersionPage: React.FC = () => {
  const { kv_key } = useParams<{ kv_key: string }>();
  const [searchParams] = useSearchParams();
  const { user } = useSelector((state: RootState) => state.auth);

  // Costruisci l'URL dell'iframe con i parametri opzionali
  const getIframeUrl = () => {
    const url = new URL(`${API_URL}/v/${kv_key}.html`);
    
    // Aggiungi roomId se presente nell'URL
    const roomId = searchParams.get('roomId');
    if (roomId) {
      url.searchParams.append('roomId', roomId);
    }

    // Aggiungi username se l'utente è autenticato
    if (user?.username) {
      url.searchParams.append('username', user.username);
    }

    return url.toString();
  };

  if (!kv_key) {
    return (
      <div className="fixed inset-0 bg-black text-white flex items-center justify-center">
        Version not found
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black">
      <div className="h-16">
        <GameNavbar kv_key={kv_key} />
      </div>
      <div className="h-[calc(100vh-64px)]">
        <iframe
          src={getIframeUrl()}
          className="w-full h-full"
          title="Game Version"
        />
      </div>
    </div>
  );
}; 