import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { gamesApi, getGameImageUrl } from '../../services/api';
import { Game } from '../../types/game';

export const RecentGamesBar: React.FC = () => {
  const navigate = useNavigate();
  const [games, setGames] = useState<Game[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [isShaking, setIsShaking] = useState(false);

  useEffect(() => {
    const fetchRecentGames = async () => {
      try {
        const { games: recentGames } = await gamesApi.getPublic('new', 1);
        setGames(recentGames);
      } catch (error) {
        console.error('Failed to load recent games:', error);
      }
    };

    fetchRecentGames();
  }, []);

  useEffect(() => {
    if (games.length === 0) return;

    const interval = setInterval(() => {
      setIsAnimating(true);
      setTimeout(() => {
        setCurrentIndex(prev => (prev + 1) % games.length);
        setIsAnimating(false);
      }, 500);
    }, 5000);

    return () => clearInterval(interval);
  }, [games]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsShaking(true);
      setTimeout(() => setIsShaking(false), 300);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const currentGame = games[currentIndex];

  return (
    <>
      <style>
        {`
          @keyframes shake {
            0% { transform: rotate(0deg); }
            25% { transform: rotate(8deg); }
            50% { transform: rotate(-8deg); }
            75% { transform: rotate(8deg); }
            100% { transform: rotate(0deg); }
          }
          .shake {
            animation: shake 0.3s ease-in-out;
          }
        `}
      </style>
      <div className="w-full border-b border-white/10">
        <div className="max-w-7xl mx-auto py-2 px-6">
          <div className="flex justify-end items-center space-x-4">
            <a 
              href="https://springboard.pancakeswap.finance/base/token/0x0ae8a62393967293ea2a44775110c5a4eb423e8e" 
              target="_blank" 
              rel="noopener noreferrer"
              className={`font-bold text-emerald-400 hover:text-emerald-300 transition-colors ${isShaking ? 'shake' : ''}`}
            >
              buy
            </a>
            <span className="text-white/20">|</span>
            <a 
              href="https://t.me/degs_ai" 
              target="_blank" 
              rel="noopener noreferrer"
              className={`font-bold text-emerald-400 hover:text-emerald-300 transition-colors ${isShaking ? 'shake' : ''}`}
            >
              telegram
            </a>
            <span className="text-white/20">|</span>
            <a 
              href="https://x.com/degs_ai" 
              target="_blank" 
              rel="noopener noreferrer"
              className={`font-bold text-emerald-400 hover:text-emerald-300 transition-colors ${isShaking ? 'shake' : ''}`}
            >
              twitter
            </a>
          </div>
        </div>
      </div>

      <div className="w-full bg-gradient-to-r from-black via-white/5 to-black border-b border-white/10">
        <div className="max-w-7xl mx-auto">
          <div className="py-2 px-4 h-[60px] flex items-center">
            {games.length > 0 && currentGame && (
              <div className={`flex justify-center w-full transition-opacity duration-500 ${isAnimating ? 'opacity-0' : 'opacity-100'}`}>
                <div 
                  className={`inline-flex items-center cursor-pointer group ${isShaking ? 'shake' : ''}`}
                  onClick={() => navigate(`/g/${currentGame.kv_key}`)}
                >
                  <div className="w-10 h-10 bg-white/10 rounded-lg overflow-hidden mr-3 group-hover:ring-2 ring-emerald-400/50 transition-all">
                    <img 
                      src={getGameImageUrl(currentGame.kv_key)}
                      alt={currentGame.name}
                      className="w-full h-full object-cover"
                      onError={(e) => {
                        setImageError(true);
                        e.currentTarget.style.display = 'none';
                      }}
                    />
                    {imageError && (
                      <div className="w-full h-full flex items-center justify-center bg-emerald-600 text-white text-lg font-bold">
                        {currentGame.name.charAt(0).toUpperCase()}
                      </div>
                    )}
                  </div>
                  
                  <div className="flex flex-col justify-center">
                    <div className="font-semibold text-base group-hover:text-emerald-400 transition-colors">
                      {currentGame.name}
                    </div>
                    <div className="text-white/40 text-sm">
                      <span className="text-white/60">{currentGame.author.username}</span>
                      <span className="text-emerald-400"> published this game</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}; 