import React from 'react';
import { useNavigate } from 'react-router-dom';

interface EnergyDisplayProps {
  energies: number;
  stakedAmount: number;
}

export const EnergyDisplay: React.FC<EnergyDisplayProps> = ({ energies, stakedAmount }) => {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate('/token')}
      className="h-8 flex items-center space-x-4 px-4 bg-white/5 rounded-lg hover:bg-white/10 transition-colors"
    >
      <div className="flex items-center space-x-2">
        <svg className="w-4 h-4 text-emerald-400" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z" clipRule="evenodd" />
        </svg>
        <span className="text-white font-medium">{energies}</span>
      </div>
      <div className="hidden md:flex items-center space-x-4">
        <span className="text-white/20">|</span>
        <div className="text-sm text-white/60">
          <span className="font-medium text-white">{stakedAmount}</span> DEGS
        </div>
      </div>
    </button>
  );
}; 