import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUser } from '../../store/slices/authSlice';
import { Toast } from '../common/Toast';
import { gamesApi, userApi } from '../../services/api';
import { GenerationToolbar } from './GenerationToolbar';
import Confetti from 'react-confetti';

const GenerationWaitingContent: React.FC = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const startTime = Date.now();
    const duration = 30000;
    let frameId: number;

    const animate = () => {
      const elapsed = Date.now() - startTime;
      const rawProgress = Math.min(elapsed / duration, 1);
      setProgress(rawProgress * 100);

      if (rawProgress < 1) {
        frameId = requestAnimationFrame(animate);
      }
    };

    frameId = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(frameId);
  }, []);

  return (
    <div className="min-h-[400px] flex flex-col justify-between py-4 px-4 md:py-8 md:px-8">
      {/* Title */}
      <div className="text-center">
        <div className="text-xl md:text-2xl font-bold text-white">Generating Your Game</div>
      </div>

      {/* Loading bar */}
      <div className="relative h-2 bg-white/10 rounded-full overflow-hidden my-8">
        <div 
          className="absolute inset-y-0 left-0 bg-emerald-500/30 rounded-full"
          style={{ width: `${progress}%` }}
        />
      </div>

      {/* Clickable placeholder image */}
      <Link 
        to="/ads"
        target="_blank"
        rel="noopener noreferrer"
        className="block w-full cursor-pointer group"
      >
        <div className="w-full aspect-[3/2] bg-white/5 rounded-lg overflow-hidden relative mx-auto max-w-2xl group-hover:bg-white/10 transition-colors">
          <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/10 to-transparent animate-shimmer" 
               style={{ 
                 backgroundSize: '200% 100%',
                 animation: 'shimmer 2s infinite'
               }}
          />
          <div className="absolute inset-0 flex items-center justify-center p-4 text-center">
            <span className="text-white/80 text-sm md:text-base font-bold">Click to learn about advertising opportunities</span>
          </div>
        </div>
      </Link>

      {/* Warning message */}
      <div className="text-center text-white/80 bg-red-500/10 p-4 rounded-lg border border-red-500/20 mt-8">
        <div className="flex items-center justify-center space-x-2">
          <svg className="w-5 h-5 text-red-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
          </svg>
          <span className="text-sm md:text-base">Do not close this page or the generation will fail.</span>
        </div>
      </div>
    </div>
  );
};

const styles = document.createElement('style');
styles.textContent = `
  @keyframes shimmer {
    0% { background-position: 200% 0; }
    100% { background-position: -200% 0; }
  }
`;
document.head.appendChild(styles);

interface GenerationModalProps {
  isOpen: boolean;
  onClose: () => void;
  parentGame?: {
    kv_key: string;
  };
}

interface VersionPrompt {
  kv_key: string;
  username: string;
  prompt: string;
  version_number: number;
  created_at: string;
}

const getTimeAgo = (date: string) => {
  const seconds = Math.floor((new Date().getTime() - new Date(date).getTime()) / 1000);

  let interval = seconds / 31536000; // years
  if (interval > 1) return Math.floor(interval) + ' years ago';
  
  interval = seconds / 2592000; // months
  if (interval > 1) return Math.floor(interval) + ' months ago';
  
  interval = seconds / 86400; // days
  if (interval > 1) return Math.floor(interval) + ' days ago';
  
  interval = seconds / 3600; // hours
  if (interval > 1) return Math.floor(interval) + ' hours ago';
  
  interval = seconds / 60; // minutes
  if (interval > 1) return Math.floor(interval) + ' minutes ago';
  
  return Math.floor(seconds) + ' seconds ago';
};

const FirstGameSuccess: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="h-full flex flex-col items-center justify-center overflow-hidden">
      <Confetti
        width={windowSize.width}
        height={windowSize.height}
        recycle={false}
        numberOfPieces={500}
      />
      
      <div className="w-full max-w-lg text-center px-4">
        <h2 className="text-2xl md:text-3xl font-bold mb-4">
          your First Game on DEGS
        </h2>
        
        <div className="space-y-4 text-base md:text-lg text-white/80">
          <p>
            Welcome to DEGS! You've just taken your first step into game creation.
          </p>
          
          <div className="bg-white/5 p-4 md:p-6 rounded-lg space-y-4">
            <div className="flex items-start space-x-3">
              <svg className="w-6 h-6 text-emerald-400 mt-1 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
              </svg>
              <p className="text-sm md:text-base text-left">
                You can continue improving your game using the <span className="text-white font-medium">improve</span> button at the top of the page to fix issues or add new features.
              </p>
            </div>
            
            <div className="flex items-start space-x-3">
              <svg className="w-6 h-6 text-emerald-400 mt-1 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
              </svg>
              <p className="text-sm md:text-base text-left">
                When you're ready, use the <span className="text-emerald-400 font-medium">publish</span> button in the top left to share your creation with the community.
              </p>
            </div>
            
            <div className="flex items-start space-x-3">
              <svg className="w-6 h-6 text-emerald-400 mt-1 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
              </svg>
              <p className="text-sm md:text-base text-left">
                Pro tip: Start with simple, fun concepts. The best games often come from straightforward ideas executed well!
              </p>
            </div>
          </div>
        </div>
        
        <button
          onClick={onClose}
          className="mt-6 px-6 py-3 bg-emerald-500 text-white font-medium rounded-lg hover:bg-emerald-600 transition-colors w-full"
        >
          Let's Go!
        </button>
      </div>
    </div>
  );
};

export const GenerationModal: React.FC<GenerationModalProps> = ({
  isOpen,
  onClose,
  parentGame,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [prompt, setPrompt] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [generationError, setGenerationError] = useState<string | null>(null);
  const [versionHistory, setVersionHistory] = useState<VersionPrompt[]>([]);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const [isMultiplayer, setIsMultiplayer] = useState(false);
  const loadedGameRef = useRef<string | null>(null);
  const [showFirstGameSuccess, setShowFirstGameSuccess] = useState(false);
  const [generatedKvKey, setGeneratedKvKey] = useState<string | null>(null);

  useEffect(() => {
    const fetchVersionHistory = async () => {
      if (!parentGame) return;
      
      if (loadedGameRef.current === parentGame.kv_key) return;
      
      try {
        setIsLoadingHistory(true);
        const isVersionPage = window.location.pathname.startsWith('/v/');
        const { versions } = isVersionPage 
          ? await gamesApi.getVersionPrompts(parentGame.kv_key)
          : await gamesApi.getGamePrompts(parentGame.kv_key);
        setVersionHistory(versions.sort((a, b) => 
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        ));
        loadedGameRef.current = parentGame.kv_key;
      } catch (error) {
        console.error('Failed to load version history:', error);
      } finally {
        setIsLoadingHistory(false);
      }
    };

    if (isOpen && parentGame) {
      fetchVersionHistory();
    }
  }, [isOpen, parentGame]);

  useEffect(() => {
    if (!isOpen) {
      setPrompt('');
      setGenerationError(null);
    }
  }, [isOpen]);

  const handleTranscriptionComplete = (text: string) => {
    setPrompt(currentPrompt => {
      return currentPrompt ? `${currentPrompt} ${text}` : text;
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!prompt.trim()) return;

    try {
      setIsGenerating(true);
      setGenerationError(null);

      const parentKvKey = parentGame && versionHistory.length > 0
        ? versionHistory[0].kv_key
        : parentGame?.kv_key;

      const response = await gamesApi.generate({
        prompt,
        ...(parentKvKey ? { parent_kv_key: parentKvKey } : {}),
        ...((!parentGame || versionHistory.length === 0) && isMultiplayer ? { is_multiplayer: true } : {}),
      });

      if (response.status === 2) {
        try {
          const { user } = await userApi.getMe();
          dispatch(setUser(user));
        } catch (err) {
          console.error('Failed to update user data after generation:', err);
        }

        setGeneratedKvKey(response.kv_key);

        if (response.is_first_creation) {
          setShowFirstGameSuccess(true);
        } else {
          Toast.success('Game generated successfully!');
          onClose();
          window.location.href = `/v/${response.kv_key}`;
        }
      } else {
        setGenerationError(response.error || 'Failed to generate game');
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to start generation';
      setGenerationError(errorMessage);
    } finally {
      setIsGenerating(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-black/90 p-8 rounded-lg border border-white/10 w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        {isGenerating ? (
          <GenerationWaitingContent />
        ) : showFirstGameSuccess && generatedKvKey ? (
          <FirstGameSuccess 
            onClose={() => {
              onClose();
              window.location.href = `/v/${generatedKvKey}`;
            }} 
          />
        ) : (
          <>
            <h2 className="text-2xl font-bold mb-6">
              {parentGame ? 'Improve Game' : 'Create New Game'}
            </h2>

            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="flex flex-col">
                <textarea
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                  rows={4}
                  className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-t-lg focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-transparent"
                  placeholder={parentGame 
                    ? "Describe how you want to improve this game..."
                    : "Describe the game you want to create..."
                  }
                  disabled={isGenerating}
                />
                <GenerationToolbar 
                  onTranscriptionComplete={handleTranscriptionComplete} 
                  showMultiplayerToggle={false}
                  isMultiplayer={isMultiplayer}
                  onMultiplayerChange={setIsMultiplayer}
                />
              </div>

              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  onClick={onClose}
                  className="px-4 py-2 bg-white/10 text-white rounded-lg hover:bg-white/20 transition-colors disabled:opacity-50"
                  disabled={isGenerating}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isGenerating || !prompt.trim()}
                  className="px-4 py-2 bg-white text-black rounded-lg hover:bg-white/90 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-2"
                >
                  {isGenerating ? (
                    <>
                      <div className="w-4 h-4 border-2 border-black/20 border-t-black rounded-full animate-spin" />
                      <span>Generating...</span>
                    </>
                  ) : (
                    <span>{parentGame ? 'Improve' : 'Create'}</span>
                  )}
                </button>
              </div>

              {generationError && (
                <div className="p-4 bg-red-500/10 border border-red-500/20 rounded-lg text-red-400 text-sm">
                  {generationError}
                </div>
              )}

              {parentGame && (
                <div className="mt-8 space-y-4">
                  <h3 className="text-lg font-medium text-white/80">Version History</h3>
                  {isLoadingHistory ? (
                    <div className="text-center py-4">
                      <div className="w-6 h-6 border-2 border-white/20 border-t-white rounded-full animate-spin mx-auto" />
                    </div>
                  ) : (
                    <div className="space-y-3">
                      {versionHistory.map((version) => (
                        <div 
                          key={version.kv_key}
                          className="p-4 bg-white/5 rounded-lg space-y-3"
                        >
                          <div className="space-y-2">
                            <Link
                              to={`/v/${version.kv_key}`}
                              onClick={(e) => e.stopPropagation()}
                              className="block text-2xl font-bold text-emerald-400 hover:text-emerald-300 transition-colors"
                            >
                              Version {version.version_number}
                            </Link>
                            <div className="flex items-center justify-between text-sm">
                              <Link
                                to={`/user/${version.username}`}
                                onClick={(e) => e.stopPropagation()}
                                className="text-emerald-400 hover:text-emerald-300 transition-colors"
                              >
                                @{version.username}
                              </Link>
                              <span className="text-white/40">
                                {getTimeAgo(version.created_at)}
                              </span>
                            </div>
                          </div>
                          <p className="text-white/80 border-t border-white/10 pt-3">
                            {version.prompt}
                          </p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </form>
          </>
        )}
      </div>
    </div>
  );
}; 