import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, Outlet } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { store } from './store';
import { AuthProvider } from './components/auth/AuthProvider';
import { RequireUsername } from './components/auth/RequireUsername';
import { HomePage } from './pages/HomePage';
import { ProfilePage } from './pages/ProfilePage';
import { WaitingPage } from './pages/WaitingPage';
import { GamePage } from './pages/GamePage';
import { VersionPage } from './pages/VersionPage';
import { UserPage } from './pages/UserPage';
import { Navbar } from './components/Navbar';
import { LoadingScreen } from './components/common/LoadingScreen';
import { useSelector } from 'react-redux';
import { RootState } from './store';
import { LibraryPage } from './pages/LibraryPage';
import { GameVersionsPage } from './pages/GameVersionsPage';
import { TokenPage } from './pages/TokenPage';
import { AdsPage } from './pages/AdsPage';
import { AuthCallbackPage } from './pages/AuthCallbackPage';
import { LogoutPage } from './pages/LogoutPage';
import { NotificationsPage } from './pages/NotificationsPage';
import { ScrollToTop } from './components/common/ScrollToTop';

// PrivateRoute component
const PrivateRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAuthenticated, isLoading } = useSelector((state: RootState) => state.auth);
  const location = useLocation();
  const hasToken = Boolean(localStorage.getItem('session_token'));

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated && hasToken) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated && !hasToken) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return <RequireUsername>{children}</RequireUsername>;
};

const MainLayout: React.FC = () => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);

  return (
    <div className="min-h-screen bg-black">
      <Navbar />
      <main className="mt-16 min-h-[calc(100vh-64px)]">
        {isAuthenticated ? (
          <RequireUsername>
            <Outlet />
          </RequireUsername>
        ) : (
          <Outlet />
        )}
      </main>
    </div>
  );
};

const AppContent: React.FC = () => {
  const { isLoading } = useSelector((state: RootState) => state.auth);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <ScrollToTop />
      <Routes>
        {/* Game routes outside of main layout */}
        <Route path="/g/:kv_key" element={<GamePage />} />
        <Route path="/v/:kv_key" element={<VersionPage />} />
        <Route path="/auth/callback" element={<AuthCallbackPage />} />
        <Route path="/logout" element={<LogoutPage />} />

        {/* All other routes with main layout */}
        <Route element={<MainLayout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/user/:username" element={<UserPage />} />
          <Route path="/ads" element={<AdsPage />} />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <ProfilePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/library"
            element={
              <PrivateRoute>
                <LibraryPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/versions/:kv_key"
            element={
              <PrivateRoute>
                <GameVersionsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/waiting/:kv_key"
            element={
              <PrivateRoute>
                <WaitingPage />
              </PrivateRoute>
            }
          />
          <Route path="/token" element={<TokenPage />} />
          <Route path="/notifications" element={<PrivateRoute><NotificationsPage /></PrivateRoute>} />
        </Route>
      </Routes>
      <Toaster 
        position="bottom-right" 
        toastOptions={{
          duration: 3000,
          style: {
            background: '#1a1a1a',
            color: '#fff',
            borderRadius: '8px',
            border: '1px solid rgba(255, 255, 255, 0.1)',
          },
        }}
      />
    </>
  );
};

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <AuthProvider>
        <Router>
          <AppContent />
        </Router>
      </AuthProvider>
    </Provider>
  );
};

export default App;
