import React from 'react';
import { ParticleField } from '../components/common/ParticleField';

export const AdsPage: React.FC = () => {
  return (
    <div className="min-h-screen bg-black text-white relative">
      <ParticleField />
      <div className="relative z-10">
        <div className="max-w-4xl mx-auto px-4 py-8 sm:py-16">
          <div className="space-y-8 sm:space-y-12">
            {/* Header */}
            <div className="text-center space-y-4">
              <h1 className="text-3xl sm:text-4xl font-bold">DEGS Advertising Network</h1>
              <p className="text-white/60 text-base sm:text-lg max-w-2xl mx-auto">
                A fair, transparent, and decentralized advertising system powered by smart contracts
              </p>
            </div>

            {/* Main content */}
            <div className="grid gap-4 sm:gap-8 md:grid-cols-2">
              {/* How it works */}
              <div className="bg-white/5 rounded-lg p-4 sm:p-6 space-y-4">
                <h2 className="text-xl sm:text-2xl font-bold text-emerald-400">How It Works</h2>
                <div className="space-y-4 text-white/80">
                  <p>
                    Our decentralized advertising system runs on a <span className="font-semibold">smart contract</span> that manages ad space auctions every <span className="font-semibold">half-week (3.5 days)</span>.
                  </p>
                  <ul className="list-disc list-inside space-y-2 ml-4">
                    <li><span className="font-semibold">Auction duration:</span> 1 hour</li>
                    <li><span className="font-semibold">Bid currency:</span> DEGS tokens</li>
                    <li><span className="font-semibold">Minimum bid:</span> Must be higher than current highest bid</li>
                    <li><span className="font-semibold">Automatic refunds</span> for outbid participants</li>
                  </ul>
                </div>
              </div>

              {/* Benefits */}
              <div className="bg-white/5 rounded-lg p-4 sm:p-6 space-y-4">
                <h2 className="text-xl sm:text-2xl font-bold text-emerald-400">Ad Space Value</h2>
                <div className="space-y-4 text-white/80">
                  <p>
                    Your ad will be displayed in <span className="font-semibold">high-visibility locations</span>:
                  </p>
                  <ul className="list-disc list-inside space-y-2 ml-4">
                    <li><span className="font-semibold">Premium placement</span> during game generation</li>
                    <li>Featured on <span className="font-semibold">viral games</span> (50,000+ daily views)</li>
                    <li><span className="font-semibold">Maximum exposure</span> in prime locations</li>
                    <li><span className="font-semibold">3.5 days</span> of continuous display</li>
                  </ul>
                </div>
              </div>

              {/* Token Distribution */}
              <div className="bg-white/5 rounded-lg p-4 sm:p-6 space-y-4 md:col-span-2">
                <h2 className="text-xl sm:text-2xl font-bold text-emerald-400">Token Distribution</h2>
                <div className="space-y-4 text-white/80">
                  <p>
                    <span className="font-semibold">100% of the winning bid</span> amount in DEGS tokens is distributed among token holders, creating a sustainable ecosystem where community members directly benefit from the platform's advertising revenue.
                  </p>
                </div>
              </div>
            </div>

            {/* Timeline visualization */}
            <div className="bg-white/5 rounded-lg p-4 sm:p-6">
              <h2 className="text-xl sm:text-2xl font-bold text-emerald-400 mb-12">Auction Timeline</h2>
              
              {/* Mobile Timeline */}
              <div className="md:hidden space-y-8">
                <div className="relative pl-6 border-l-2 border-emerald-500/30 space-y-8">
                  <div className="relative">
                    <div className="absolute w-4 h-4 bg-emerald-500 rounded-full -left-[1.35rem] top-0"></div>
                    <p className="text-sm font-semibold text-white/80">Auction Start</p>
                    <p className="text-xs text-white/40">1 Hour Duration</p>
                  </div>
                  <div className="relative">
                    <div className="absolute w-4 h-4 bg-emerald-500 rounded-full -left-[1.35rem] top-0"></div>
                    <p className="text-sm font-semibold text-white/80">Winner Selected</p>
                    <p className="text-xs text-white/40">Automatic Distribution</p>
                  </div>
                  <div className="relative">
                    <div className="absolute w-4 h-4 bg-emerald-500 rounded-full -left-[1.35rem] top-0"></div>
                    <p className="text-sm font-semibold text-white/80">Ad Display Period</p>
                    <p className="text-xs text-white/40">3.5 Days</p>
                  </div>
                </div>
              </div>

              {/* Desktop Timeline */}
              <div className="hidden md:block">
                <div className="relative">
                  {/* Line */}
                  <div className="absolute left-0 right-0 h-0.5 top-[2.5rem] bg-emerald-500/30"></div>
                  
                  {/* Timeline points */}
                  <div className="relative grid grid-cols-3 gap-4">
                    <div className="text-center">
                      <div className="mb-8">
                        <p className="text-sm font-semibold text-white/80 whitespace-nowrap">Auction Start</p>
                        <p className="text-xs text-white/40">1 Hour Duration</p>
                      </div>
                      <div className="w-4 h-4 bg-emerald-500 rounded-full mx-auto relative z-10"></div>
                    </div>
                    <div className="text-center">
                      <div className="mb-8">
                        <p className="text-sm font-semibold text-white/80 whitespace-nowrap">Winner Selected</p>
                        <p className="text-xs text-white/40">Automatic Distribution</p>
                      </div>
                      <div className="w-4 h-4 bg-emerald-500 rounded-full mx-auto relative z-10"></div>
                    </div>
                    <div className="text-center">
                      <div className="mb-8">
                        <p className="text-sm font-semibold text-white/80 whitespace-nowrap">Ad Display Period</p>
                        <p className="text-xs text-white/40">3.5 Days</p>
                      </div>
                      <div className="w-4 h-4 bg-emerald-500 rounded-full mx-auto relative z-10"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Call to action */}
            <div className="text-center space-y-4">
              <h2 className="text-xl sm:text-2xl font-bold">Ready to Participate?</h2>
              <p className="text-white/60">
                Connect your wallet and get <span className="font-semibold">DEGS tokens</span> to start bidding on premium ad spaces.
              </p>
              <button 
                disabled
                className="px-8 py-3 bg-emerald-500/50 text-black font-bold rounded-lg cursor-not-allowed"
              >
                Coming Soon
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}; 