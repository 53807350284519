import { ethers } from 'ethers';

export const authService = {
  connectWallet: async () => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    
    if (!window.ethereum) {
      if (isIOS) {
        // Try to open MetaMask app
        window.location.href = `metamask://dapp/${window.location.href}`;
        throw new Error('Please open this page in the MetaMask browser. Redirecting...');
      }
      
      const isMobile = /Android/i.test(navigator.userAgent);
      throw new Error(
        isMobile 
          ? 'Please open this page in the MetaMask app browser or install MetaMask'
          : 'MetaMask is not installed'
      );
    }

    try {
      // Request accounts directly from ethereum object
      const accounts = await window.ethereum.request({ 
        method: 'eth_requestAccounts' 
      }) as string[];
      
      if (!accounts || accounts.length === 0) {
        throw new Error('No accounts found');
      }
      return accounts[0];
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }
      throw new Error('Failed to connect wallet');
    }
  },

  signMessage: async (challenge: string) => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    
    if (!window.ethereum) {
      if (isIOS) {
        window.location.href = `metamask://dapp/${window.location.href}`;
        throw new Error('Please open this page in the MetaMask browser. Redirecting...');
      }
      throw new Error('MetaMask is not installed');
    }

    try {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      
      // Build a clear, user-friendly message
      const message = `Welcome to degs!

This signature is only used to verify that you own this wallet address. It cannot be used to make any transactions or access your funds.

Challenge: ${challenge}

By signing this message, you are proving ownership of your wallet. This is a one-time authentication step.`;
      
      console.log('Message to sign:', message);
      
      const signature = await signer.signMessage(message);
      console.log('Signature:', signature);
      
      return { signature };
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }
      throw new Error('Failed to sign message');
    }
  },
}; 