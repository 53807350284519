import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import { setUser } from '../store/slices/authSlice';
import { Toast } from '../components/common/Toast';
import { userApi } from '../services/api';

export const ProfilePage: React.FC = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const [formData, setFormData] = useState({
    username: user?.username || '',
    bio: user?.bio || '',
    website: user?.website || '',
    tiktok_profile: user?.tiktok_profile || '',
    x_profile: user?.x_profile || '',
    twitch_profile: user?.twitch_profile || ''
  });
  const [errors, setErrors] = useState<{[key: string]: string}>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  if (!user) {
    return (
      <div className="min-h-screen bg-black text-white flex items-center justify-center">
        <p>Please connect your wallet to view your profile.</p>
      </div>
    );
  }

  const validateForm = () => {
    const newErrors: {[key: string]: string} = {};

    // Username validation
    if (formData.username) {
      if (!/^[a-zA-Z0-9_]{3,20}$/.test(formData.username)) {
        newErrors.username = 'Username must be 3-20 characters and contain only letters, numbers and underscores';
      }
    }

    // Bio validation
    if (formData.bio && formData.bio.length > 300) {
      newErrors.bio = 'Bio must be less than 300 characters';
    }

    // Website validation
    if (formData.website) {
      try {
        new URL(formData.website);
      } catch {
        newErrors.website = 'Please enter a valid URL';
      }
    }

    // Social profiles validation
    const socialFields = ['tiktok_profile', 'x_profile', 'twitch_profile'] as const;
    socialFields.forEach(field => {
      const value = formData[field];
      if (value && !/^[a-zA-Z0-9_]{1,30}$/.test(value.replace(/^@/, ''))) {
        newErrors[field] = `Invalid ${field.replace('_', ' ')} format`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    try {
      setIsSubmitting(true);
      const { user: updatedUser } = await userApi.updateProfile(formData);
      dispatch(setUser(updatedUser));
      Toast.success('Profile updated successfully');
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Failed to update profile';
      Toast.error(message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-black text-white">
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="bg-white/5 rounded-lg p-6 border border-white/10">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-bold">Profile Settings</h1>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-white/60 mb-2">
                Wallet Address
              </label>
              <input
                type="text"
                value={user.user_address}
                disabled
                className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white/60"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-white/60 mb-2">
                Username
              </label>
              <input
                type="text"
                value={formData.username}
                onChange={(e) => setFormData({ ...formData, username: e.target.value })}
                className={`w-full px-4 py-2 bg-white/5 border ${
                  errors.username ? 'border-red-500' : 'border-white/10'
                } rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent`}
                disabled={isSubmitting}
              />
              {errors.username && (
                <p className="mt-1 text-sm text-red-500">{errors.username}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-white/60 mb-2">
                Bio
              </label>
              <textarea
                value={formData.bio}
                onChange={(e) => setFormData({ ...formData, bio: e.target.value })}
                rows={4}
                className={`w-full px-4 py-2 bg-white/5 border ${
                  errors.bio ? 'border-red-500' : 'border-white/10'
                } rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent`}
                disabled={isSubmitting}
              />
              {errors.bio && (
                <p className="mt-1 text-sm text-red-500">{errors.bio}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-white/60 mb-2">
                Website
              </label>
              <input
                type="url"
                value={formData.website}
                onChange={(e) => setFormData({ ...formData, website: e.target.value })}
                className={`w-full px-4 py-2 bg-white/5 border ${
                  errors.website ? 'border-red-500' : 'border-white/10'
                } rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent`}
                disabled={isSubmitting}
              />
              {errors.website && (
                <p className="mt-1 text-sm text-red-500">{errors.website}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-white/60 mb-2">
                TikTok Profile
              </label>
              <input
                type="text"
                value={formData.tiktok_profile}
                onChange={(e) => setFormData({ ...formData, tiktok_profile: e.target.value })}
                className={`w-full px-4 py-2 bg-white/5 border ${
                  errors.tiktok_profile ? 'border-red-500' : 'border-white/10'
                } rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent`}
                disabled={isSubmitting}
              />
              {errors.tiktok_profile && (
                <p className="mt-1 text-sm text-red-500">{errors.tiktok_profile}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-white/60 mb-2">
                X Profile
              </label>
              <input
                type="text"
                value={formData.x_profile}
                onChange={(e) => setFormData({ ...formData, x_profile: e.target.value })}
                className={`w-full px-4 py-2 bg-white/5 border ${
                  errors.x_profile ? 'border-red-500' : 'border-white/10'
                } rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent`}
                disabled={isSubmitting}
              />
              {errors.x_profile && (
                <p className="mt-1 text-sm text-red-500">{errors.x_profile}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-white/60 mb-2">
                Twitch Profile
              </label>
              <input
                type="text"
                value={formData.twitch_profile}
                onChange={(e) => setFormData({ ...formData, twitch_profile: e.target.value })}
                className={`w-full px-4 py-2 bg-white/5 border ${
                  errors.twitch_profile ? 'border-red-500' : 'border-white/10'
                } rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent`}
                disabled={isSubmitting}
              />
              {errors.twitch_profile && (
                <p className="mt-1 text-sm text-red-500">{errors.twitch_profile}</p>
              )}
            </div>

            <div className="flex justify-end space-x-4">
              <button
                type="submit"
                disabled={isSubmitting}
                className="px-4 py-2 bg-white text-black rounded-lg hover:bg-white/90 transition-colors disabled:opacity-50"
              >
                {isSubmitting ? 'Saving...' : 'Save Changes'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}; 