import React, { useState, useRef, useCallback } from 'react';
import { audioApi } from '../../services/api';
import { Toast } from '../common/Toast';

interface GenerationToolbarProps {
  onTranscriptionComplete: (text: string) => void;
  showMultiplayerToggle?: boolean;
  isMultiplayer?: boolean;
  onMultiplayerChange?: (value: boolean) => void;
}

// Maximum file size in bytes (5MB)
const MAX_FILE_SIZE = 5 * 1024 * 1024;

export const GenerationToolbar: React.FC<GenerationToolbarProps> = ({
  onTranscriptionComplete,
  showMultiplayerToggle = false,
  isMultiplayer = false,
  onMultiplayerChange,
}) => {
  const [isRecording, setIsRecording] = useState(false);
  const [recordingProgress, setRecordingProgress] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const chunksRef = useRef<Blob[]>([]);
  const startTimeRef = useRef<number>(0);
  const progressIntervalRef = useRef<number>();
  const audioStreamRef = useRef<MediaStream | null>(null);
  
  const MAX_RECORDING_TIME = 60000; // 60 seconds
  const MIN_RECORDING_TIME = 3000;  // 3 seconds

  // Determine the best audio format for the browser
  const getAudioConfig = useCallback(() => {
    const checkMimeTypeSupport = (mimeType: string) => MediaRecorder.isTypeSupported(mimeType);
    const fileType = checkMimeTypeSupport('audio/mp4') ? 'mp4' : 'webm';
    const mimeType = `audio/${fileType}`;
    
    return { fileType, mimeType };
  }, []);

  const cleanupRecording = useCallback(() => {
    if (audioStreamRef.current) {
      audioStreamRef.current.getTracks().forEach(track => track.stop());
    }
    if (progressIntervalRef.current) {
      clearInterval(progressIntervalRef.current);
    }
    setIsRecording(false);
    setRecordingProgress(0);
    chunksRef.current = [];
  }, []);

  const startRecording = useCallback(async () => {
    try {
      // Clean up any previous recording state
      cleanupRecording();

      const stream = await navigator.mediaDevices.getUserMedia({ 
        audio: {
          channelCount: 1,
          sampleRate: 16000,
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
        } 
      });
      
      audioStreamRef.current = stream;
      const { mimeType, fileType } = getAudioConfig();
      
      const mediaRecorder = new MediaRecorder(stream, {
        mimeType,
        audioBitsPerSecond: 128000,
      });

      mediaRecorderRef.current = mediaRecorder;
      chunksRef.current = [];
      
      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunksRef.current.push(event.data);
        }
      };

      mediaRecorder.onstop = async () => {
        try {
          const recordingDuration = (Date.now() - startTimeRef.current) / 1000; // Convert to seconds
          if (recordingDuration < 3) {
            setError('Recording is too short. Please record for at least 3 seconds.');
            Toast.error('Recording too short');
            cleanupRecording();
            return;
          }

          // Create audio blob from chunks
          const audioBlob = new Blob(chunksRef.current, { type: mimeType });
          
          // Check file size
          if (audioBlob.size > MAX_FILE_SIZE) {
            console.log('File too large:', audioBlob.size, 'bytes');
            setError('Recording is too large. Please try a shorter recording.');
            cleanupRecording();
            return;
          }

          // Debug: log audio info
          console.log('Audio size:', audioBlob.size, 'bytes');
          console.log('Audio type:', audioBlob.type);
          console.log('Audio duration:', recordingDuration, 'seconds');

          setIsProcessing(true);
          setError(null);
          
          const response = await audioApi.transcribe(audioBlob);
          
          if (response.success && response.text) {
            // Trim the transcribed text to remove any leading/trailing whitespace
            onTranscriptionComplete(response.text.trim());
            Toast.success('Audio transcribed successfully!');
          } else {
            throw new Error(response.error?.message || 'Transcription failed');
          }
        } catch (err) {
          let errorMessage = 'Failed to transcribe audio. Please try again.';
          
          if (err instanceof Error) {
            if (err.message.includes('401')) {
              errorMessage = 'Please sign in to use audio transcription.';
            } else if (err.message.includes('413')) {
              errorMessage = 'Recording is too large. Please try a shorter recording.';
            } else if (err.message.includes('415')) {
              errorMessage = 'Audio format not supported. Please try again.';
            }
          }
          
          setError(errorMessage);
          Toast.error(errorMessage);
          console.error('Transcription error:', err);
        } finally {
          setIsProcessing(false);
          cleanupRecording();
        }
      };

      // Start recording
      mediaRecorder.start(1000);
      startTimeRef.current = Date.now();
      setIsRecording(true);
      setError(null);

      // Update progress bar
      progressIntervalRef.current = window.setInterval(() => {
        const elapsed = Date.now() - startTimeRef.current;
        const progress = Math.min((elapsed / MAX_RECORDING_TIME) * 100, 100);
        setRecordingProgress(progress);

        if (elapsed >= MAX_RECORDING_TIME) {
          stopRecording();
        }
      }, 100);

    } catch (err) {
      if (err instanceof Error) {
        if (err.name === 'NotAllowedError') {
          setError('Microphone access denied. Please allow microphone access to record audio.');
        } else if (err.name === 'NotFoundError') {
          setError('No microphone found. Please connect a microphone and try again.');
        } else if (err.name === 'NotReadableError') {
          setError('Could not access your microphone. Please check your device settings.');
        } else {
          setError('Failed to start recording. Please check your microphone connection.');
        }
        Toast.error('Recording error');
      }
      console.error('Recording error:', err);
      cleanupRecording();
    }
  }, [onTranscriptionComplete, getAudioConfig, cleanupRecording]);

  const stopRecording = useCallback(() => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
      mediaRecorderRef.current.stop();
    }
  }, []);

  return (
    <div className="flex items-center justify-between px-4 py-2 bg-white/5 border-x border-b border-white/10 rounded-b-lg">
      {/* Left side - Audio recording */}
      <div>
        {/* Record button */}
        <button
          onClick={(e) => {
            e.preventDefault(); // Prevent form submission
            isRecording ? stopRecording() : startRecording();
          }}
          type="button" // Explicitly set button type to prevent form submission
          disabled={isProcessing}
          className={`flex items-center space-x-2 px-3 py-1 rounded-lg transition-colors ${
            isRecording
              ? 'bg-red-500 hover:bg-red-600'
              : 'bg-emerald-500 hover:bg-emerald-600'
          } ${isProcessing ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          <svg 
            className={`w-4 h-4 ${isRecording ? 'animate-pulse' : ''}`} 
            fill="currentColor" 
            viewBox="0 0 24 24"
          >
            <path d="M12 14c1.66 0 3-1.34 3-3V5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3z"/>
            <path d="M17 11c0 2.76-2.24 5-5 5s-5-2.24-5-5H5c0 3.53 2.61 6.43 6 6.92V21h2v-3.08c3.39-.49 6-3.39 6-6.92h-2z"/>
          </svg>
          <span className="text-sm">
            {isProcessing 
              ? 'Processing...' 
              : isRecording 
                ? 'Stop' 
                : 'Say it'}
          </span>
        </button>

        {/* Progress bar */}
        {isRecording && (
          <div className="flex-1 h-1 bg-white/10 rounded-full overflow-hidden">
            <div
              className="h-full bg-emerald-500 transition-all duration-100"
              style={{ width: `${recordingProgress}%` }}
            />
          </div>
        )}

        {/* Error message */}
        {error && (
          <div className="text-red-400 text-sm">
            {error}
          </div>
        )}
      </div>

      {/* Right side - Multiplayer toggle */}
      {showMultiplayerToggle && (
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            onMultiplayerChange?.(!isMultiplayer);
          }}
          className={`px-3 py-1.5 text-sm font-medium rounded-lg transition-colors border ${
            isMultiplayer
              ? 'bg-emerald-500/20 text-emerald-500 border-emerald-500/20 hover:bg-emerald-500/30'
              : 'bg-white/5 text-white/60 border-white/10 hover:bg-white/10'
          }`}
        >
          multiplayer
        </button>
      )}
    </div>
  );
}; 