import { User, ApiError } from '../types/auth';
import { NotificationsResponse } from '../types/notification';

export type { ApiError };

export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8787';

// Helper function to get game image URL
export const getGameImageUrl = (kvKey: string) => `${API_URL}/image/${kvKey}.webp`;

// Helper function to handle API responses
async function handleResponse<T>(response: Response): Promise<T> {
  if (!response.ok) {
    const error = await response.json().catch(() => ({ error: 'Unknown error' }));
    throw new Error(error.error || 'Unknown error');
  }
  return response.json();
}

// Helper function to get auth header
function getAuthHeader(): HeadersInit {
  const token = localStorage.getItem('session_token');
  if (!token) {
    throw new Error('No session token found');
  }
  return {
    'Authorization': `Bearer ${token}`,
  };
}

// Helper function to get headers for requests with body
function getRequestHeaders(requiresAuth: boolean = false): HeadersInit {
  return {
    'Content-Type': 'application/json',
    ...(requiresAuth ? getAuthHeader() : {}),
  };
}

// Auth API
export const authApi = {
  getChallenge: () => 
    fetch(`${API_URL}/api/auth/challenge`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    }).then(handleResponse<{ challenge: string }>),

  verifySignature: (address: string, signature: string, challenge: string) =>
    fetch(`${API_URL}/api/auth/verify`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ address, signature, challenge }),
    }).then(handleResponse<{
      session_token: string;
      user: User;
    }>),

  sendMagicLink: (email: string) =>
    fetch(`${API_URL}/api/auth/email/magic-link`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    }).then(handleResponse<{ success: boolean }>),

  verifyMagicLink: (token: string) =>
    fetch(`${API_URL}/api/auth/email/verify`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token }),
    }).then(handleResponse<{
      session_token: string;
      user: User;
    }>),

  emailLogin: (email: string, password: string) =>
    fetch(`${API_URL}/api/auth/email/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, password }),
    }).then(handleResponse<{
      session_token: string;
      user: User;
    }>),

  emailRegister: (email: string, password: string) =>
    fetch(`${API_URL}/api/auth/email/register`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, password }),
    }).then(handleResponse<{
      session_token: string;
      user: User;
    }>),

  resetPassword: (email: string) =>
    fetch(`${API_URL}/api/auth/email/reset`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    }).then(handleResponse<{ success: boolean }>),
};

// User API
export const userApi = {
  getMe: () =>
    fetch(`${API_URL}/api/user/me`, {
      headers: getAuthHeader(),
    }).then(handleResponse<{ user: User }>),

  updateProfile: (data: Partial<User>) =>
    fetch(`${API_URL}/api/user`, {
      method: 'PATCH',
      headers: getRequestHeaders(true),
      body: JSON.stringify(data),
    }).then(handleResponse<{ user: User }>),

  getProfile: (username: string) => {
    const token = localStorage.getItem('session_token');
    const headers: HeadersInit = token 
      ? { 'Authorization': `Bearer ${token}` }
      : {};

    return fetch(`${API_URL}/api/users/${username}`, {
      headers
    }).then(handleResponse<{
      user: {
        username: string;
        bio: string | null;
        website: string | null;
        tiktok_profile: string | null;
        x_profile: string | null;
        twitch_profile: string | null;
        followers_count: number;
        following_count: number;
        is_following: boolean;
      };
    }>);
  },
};

// Games API
export const gamesApi = {
  generate: (data: { prompt: string; parent_kv_key?: string }) =>
    fetch(`${API_URL}/api/games/generate`, {
      method: 'POST',
      headers: getRequestHeaders(true),
      body: JSON.stringify(data),
    }).then(handleResponse<{
      kv_key: string;
      status: number;
      is_first_creation: boolean;
      error?: string;
    }>),

  getStatus: (kvKey: string) =>
    fetch(`${API_URL}/api/games/${kvKey}/status`).then(handleResponse<{
      status: number;
      code?: string;
      error?: string;
    }>),

  publish: (kvKey: string) =>
    fetch(`${API_URL}/api/games/${kvKey}/public`, {
      method: 'PATCH',
      headers: getAuthHeader(),
    }).then(handleResponse<{ 
      success: boolean;
      is_first_publish: boolean;
      is_public: boolean;
    }>),

  getPublic: (filter: "new" | "top_day" | "top_week" | "top_month" | "following" | "liked", page: number) => {
    const params = new URLSearchParams({ 
      page: page.toString(),
      filter: filter
    });

    // Add sort and order only for "new" filter
    if (filter === "new") {
      params.append("sort", "created_at");
      params.append("order", "desc");
    }

    // Add auth header for authenticated-only filters
    const headers: HeadersInit = (filter === "following" || filter === "liked")
      ? getAuthHeader()
      : {};

    return fetch(`${API_URL}/api/games/public?${params.toString()}`, {
      headers
    }).then(handleResponse<{
      games: Array<{
        id: number;
        root_game_id: number;
        kv_key: string;
        name: string;
        author: {
          id: number;
          username: string;
        };
        version_number: number;
        variant_index: number;
        created_at: string;
        likes_count: number;
        views_count: number;
        comments_count: number;
      }>;
      pagination: {
        total: number;
        page: number;
        pages: number;
        has_more: boolean;
      };
    }>);
  },

  getUserGames: (username: string, type: "public" | "all", page: number) =>
    fetch(`${API_URL}/api/games/user/${username}?type=${type}&page=${page}`, {
      headers: type === 'all' ? getAuthHeader() : {},
    }).then(handleResponse<{
      games: Array<{
        id: number;
        root_game_id: number;
        kv_key: string;
        name: string;
        version_number: number;
        variant_index: number;
        is_public: boolean;
        created_at: string;
        likes_count: number;
        views_count: number;
      }>;
      pagination: {
        total: number;
        page: number;
        pages: number;
        has_more: boolean;
      };
    }>),

  getVersions: (kvKey: string) =>
    fetch(`${API_URL}/api/games/${kvKey}/versions`, {
      headers: getAuthHeader(),
    }).then(handleResponse<{
      versions: {
        [key: string]: Array<{
          kv_key: string;
          name: string;
          author_username: string;
          variant_index: number;
          created_at: string;
          is_public: boolean;
        }>;
      };
    }>),

  like: (kvKey: string) =>
    fetch(`${API_URL}/api/games/${kvKey}/like`, {
      method: 'POST',
      headers: getAuthHeader(),
    }).then(handleResponse<{
      success: boolean;
      liked: boolean;
    }>),

  view: (kvKey: string) =>
    fetch(`${API_URL}/api/games/${kvKey}/view`, {
      method: 'POST',
    }).then(handleResponse<{
      success: boolean;
      total_views: number;
    }>),

  getComments: (kvKey: string) =>
    fetch(`${API_URL}/api/games/${kvKey}/comments`).then(handleResponse<{
      comments: Array<{
        id: number;
        content: string;
        author_username: string;
        created_at: string;
      }>;
    }>),

  addComment: (kvKey: string, content: string) =>
    fetch(`${API_URL}/api/games/${kvKey}/comments`, {
      method: 'POST',
      headers: getRequestHeaders(true),
      body: JSON.stringify({ content }),
    }).then(handleResponse<{ success: boolean }>),

  getGame: (kvKey: string) => {
    const token = localStorage.getItem('session_token');
    const headers: HeadersInit = token 
      ? { 'Authorization': `Bearer ${token}` }
      : {};

    return fetch(`${API_URL}/api/games/${kvKey}`, {
      headers
    }).then(handleResponse<{
      game: {
        id: number;
        kv_key: string;
        name: string;
        author_id: number;
        author_username: string;
        is_public: boolean;
        version_number: number;
        variant_index: number;
        created_at: string;
        likes_count: number;
        views_count: number;
        is_liked: boolean;
        is_author: boolean;
        public_version_key: string | false;
      };
    }>);
  },

  getGamePrompts: (kvKey: string) =>
    fetch(`${API_URL}/api/games/${kvKey}/prompts`).then(handleResponse<{
      versions: Array<{
        kv_key: string;
        username: string;
        prompt: string;
        version_number: number;
        created_at: string;
      }>;
    }>),

  getVersionPrompts: (kvKey: string) =>
    fetch(`${API_URL}/api/games/${kvKey}/version-prompts`).then(handleResponse<{
      versions: Array<{
        kv_key: string;
        username: string;
        prompt: string;
        version_number: number;
        created_at: string;
      }>;
    }>),
};

// Social API
export const socialApi = {
  follow: (username: string) =>
    fetch(`${API_URL}/api/follow`, {
      method: 'POST',
      headers: getRequestHeaders(true),
      body: JSON.stringify({ username }),
    }).then(handleResponse<{ success: boolean }>),

  unfollow: (username: string) =>
    fetch(`${API_URL}/api/follow`, {
      method: 'DELETE',
      headers: getRequestHeaders(true),
      body: JSON.stringify({ username }),
    }).then(handleResponse<{ success: boolean }>),

  getNotifications: (page: number) =>
    fetch(`${API_URL}/api/notifications?page=${page}`, {
      headers: getAuthHeader(),
    }).then(handleResponse<{
      notifications: Array<{
        id: number;
        type: "comment" | "like" | "follow" | "new_game_version";
        message: string;
        is_read: boolean;
        created_at: string;
        data: {
          username?: string;
          game_name?: string;
          object_type: "game" | "user";
          object_id: number;
        };
      }>;
      pagination: {
        total: number;
        page: number;
        pages: number;
        has_more: boolean;
      };
    }>),

  markNotificationRead: (id: number) =>
    fetch(`${API_URL}/api/notifications/${id}/read`, {
      method: 'POST',
      headers: getAuthHeader(),
    }).then(handleResponse<{ success: boolean }>),
};

// Audio API
export const audioApi = {
  transcribe: (audioFile: Blob) => {
    const formData = new FormData();
    const extension = audioFile.type === 'audio/mp4' ? 'mp4' : 'webm';
    formData.append('file', audioFile, `recording.${extension}`);

    return fetch(`${API_URL}/api/transcribe`, {
      method: 'POST',
      headers: getAuthHeader(),
      body: formData,
    }).then(handleResponse<{
      success: boolean;
      text?: string;
      error?: {
        code: number;
        message: string;
      };
    }>);
  },
};

export const notificationsApi = {
  getNotifications: async (page: number = 1): Promise<NotificationsResponse> => {
    const response = await fetch(`${API_URL}/api/notifications?page=${page}`, {
      headers: getAuthHeader(),
    });
    return handleResponse(response);
  },

  markAsRead: async (id: number): Promise<{ success: boolean }> => {
    const response = await fetch(`${API_URL}/api/notifications/${id}/read`, {
      method: 'POST',
      headers: getAuthHeader(),
    });
    return handleResponse(response);
  },

  markAllAsRead: async (): Promise<{ success: boolean }> => {
    const response = await fetch(`${API_URL}/api/notifications/read-all`, {
      method: 'POST',
      headers: getAuthHeader(),
    });
    return handleResponse(response);
  }
}; 