import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

export type FilterType = "new" | "top_day" | "top_week" | "top_month" | "following" | "liked";

interface GameFiltersProps {
  selectedFilter: FilterType;
  onFilterChange: (filter: FilterType) => void;
}

const FILTER_STORAGE_KEY = 'game_filter';
const FILTER_TIMESTAMP_KEY = 'game_filter_timestamp';
const ONE_HOUR = 60 * 60 * 1000; // 1 ora in millisecondi

const getStoredFilter = (): FilterType | null => {
  const storedTimestamp = localStorage.getItem(FILTER_TIMESTAMP_KEY);
  if (!storedTimestamp) return null;

  const timestamp = parseInt(storedTimestamp, 10);
  const now = Date.now();

  // Se è passata più di un'ora, rimuovi il filtro
  if (now - timestamp > ONE_HOUR) {
    localStorage.removeItem(FILTER_STORAGE_KEY);
    localStorage.removeItem(FILTER_TIMESTAMP_KEY);
    return null;
  }

  return localStorage.getItem(FILTER_STORAGE_KEY) as FilterType || null;
};

export const GameFilters: React.FC<GameFiltersProps> = ({
  selectedFilter,
  onFilterChange,
}) => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const [showTopMenu, setShowTopMenu] = useState(false);
  const topMenuRef = useRef<HTMLDivElement>(null);

  // Carica il filtro salvato all'avvio
  useEffect(() => {
    const storedFilter = getStoredFilter();
    if (storedFilter && storedFilter !== selectedFilter) {
      onFilterChange(storedFilter);
    }
  }, []);

  // Salva il filtro quando cambia
  const handleFilterChange = (filter: FilterType) => {
    localStorage.setItem(FILTER_STORAGE_KEY, filter);
    localStorage.setItem(FILTER_TIMESTAMP_KEY, Date.now().toString());
    onFilterChange(filter);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (topMenuRef.current && !topMenuRef.current.contains(event.target as Node)) {
        setShowTopMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleTopClick = () => {
    setShowTopMenu(!showTopMenu);
  };

  return (
    <div className="flex items-center space-x-2 overflow-x-auto sm:overflow-visible">
      <div className="relative flex-1 sm:flex-initial" ref={topMenuRef}>
        <button
          onClick={handleTopClick}
          className={`w-full px-2 sm:px-4 py-2 rounded-lg transition-colors flex items-center justify-center space-x-1 ${
            selectedFilter.startsWith('top_')
              ? 'bg-white/10 text-white border border-white'
              : 'text-white/60 hover:text-white hover:bg-white/10'
          }`}
        >
          <span>top</span>
          <svg 
            className={`w-4 h-4 transition-transform ${showTopMenu ? 'rotate-180' : ''}`} 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </svg>
        </button>

        {showTopMenu && (
          <div className="fixed sm:absolute left-1/2 sm:left-0 -translate-x-1/2 sm:translate-x-0 mt-2 w-48 bg-black/90 backdrop-blur-sm rounded-lg border border-white/10 overflow-hidden z-50">
            <button
              onClick={() => {
                handleFilterChange('top_day');
                setShowTopMenu(false);
              }}
              className={`w-full px-4 py-2 text-left transition-colors ${
                selectedFilter === 'top_day'
                  ? 'bg-white/10 text-white border border-white'
                  : 'text-white/60 hover:text-white hover:bg-white/10'
              }`}
            >
              today
            </button>
            <button
              onClick={() => {
                handleFilterChange('top_week');
                setShowTopMenu(false);
              }}
              className={`w-full px-4 py-2 text-left transition-colors ${
                selectedFilter === 'top_week'
                  ? 'bg-white/10 text-white border border-white'
                  : 'text-white/60 hover:text-white hover:bg-white/10'
              }`}
            >
              week
            </button>
            <button
              onClick={() => {
                handleFilterChange('top_month');
                setShowTopMenu(false);
              }}
              className={`w-full px-4 py-2 text-left transition-colors ${
                selectedFilter === 'top_month'
                  ? 'bg-white/10 text-white border border-white'
                  : 'text-white/60 hover:text-white hover:bg-white/10'
              }`}
            >
              month
            </button>
          </div>
        )}
      </div>

      <button
        onClick={() => handleFilterChange('new')}
        className={`flex-1 sm:flex-initial px-2 sm:px-4 py-2 rounded-lg transition-colors ${
          selectedFilter === 'new'
            ? 'bg-white/10 text-white border border-white'
            : 'text-white/60 hover:text-white hover:bg-white/10'
        }`}
      >
        new
      </button>

      {isAuthenticated && (
        <>
          <button
            onClick={() => handleFilterChange('following')}
            className={`flex-1 sm:flex-initial px-2 sm:px-4 py-2 rounded-lg transition-colors ${
              selectedFilter === 'following'
                ? 'bg-white/10 text-white border border-white'
                : 'text-white/60 hover:text-white hover:bg-white/10'
            }`}
          >
            following
          </button>
          <button
            onClick={() => handleFilterChange('liked')}
            className={`flex-1 sm:flex-initial px-2 sm:px-4 py-2 rounded-lg transition-colors ${
              selectedFilter === 'liked'
                ? 'bg-white/10 text-white border border-white'
                : 'text-white/60 hover:text-white hover:bg-white/10'
            }`}
          >
            liked
          </button>
        </>
      )}
    </div>
  );
}; 