import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { GameNavbar } from '../components/games/GameNavbar';
import { API_URL } from '../services/api';

export const GamePage: React.FC = () => {
  const { kv_key } = useParams<{ kv_key: string }>();
  const [searchParams] = useSearchParams();
  const { user } = useSelector((state: RootState) => state.auth);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const gameContainerRef = useRef<HTMLDivElement>(null);
  const [isNavbarInteracting, setIsNavbarInteracting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Costruisci l'URL dell'iframe con i parametri opzionali
  const getIframeUrl = () => {
    const url = new URL(`${API_URL}/g/${kv_key}.html`);
    
    // Aggiungi roomId se presente nell'URL
    const roomId = searchParams.get('roomId');
    if (roomId) {
      url.searchParams.append('roomId', roomId);
    }

    // Aggiungi username se l'utente è autenticato
    if (user?.username) {
      url.searchParams.append('username', user.username);
    }

    return url.toString();
  };

  useEffect(() => {
    // Prevent default touch behaviors only in the game container
    const preventDefault = (e: Event) => {
      // Solo se l'evento proviene dal container del gioco o dall'iframe
      if (gameContainerRef.current?.contains(e.target as Node)) {
        e.preventDefault();
      }
    };

    // Prevent all possible iOS gestures in the game area
    const preventGestures = (e: Event) => {
      if (gameContainerRef.current?.contains(e.target as Node)) {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    document.addEventListener('touchstart', preventDefault, { passive: false });
    document.addEventListener('touchmove', preventDefault, { passive: false });
    document.addEventListener('gesturestart', preventGestures, { passive: false });
    document.addEventListener('gesturechange', preventGestures, { passive: false });
    document.addEventListener('gestureend', preventGestures, { passive: false });
    
    if (iframeRef.current) {
      iframeRef.current.focus();
    }

    return () => {
      document.removeEventListener('touchstart', preventDefault);
      document.removeEventListener('touchmove', preventDefault);
      document.removeEventListener('gesturestart', preventGestures);
      document.removeEventListener('gesturechange', preventGestures);
      document.removeEventListener('gestureend', preventGestures);
    };
  }, []);

  if (!kv_key) {
    return (
      <div className="fixed inset-0 bg-black text-white flex items-center justify-center">
        Game not found
      </div>
    );
  }

  const preventIOSBehavior: React.CSSProperties = {
    touchAction: 'none',
    WebkitTouchCallout: 'none',
    WebkitUserSelect: 'none',
    WebkitTapHighlightColor: 'transparent',
    userSelect: 'none',
    cursor: 'default',
    WebkitTextSizeAdjust: '100%'
  };

  return (
    <div className="fixed inset-0 bg-black">
      <div 
        className="h-12 sm:h-16"
        onMouseEnter={() => setIsNavbarInteracting(true)}
        onMouseLeave={() => {
          setIsNavbarInteracting(false);
          if (iframeRef.current) {
            iframeRef.current.focus();
          }
        }}
      >
        <GameNavbar kv_key={kv_key} />
      </div>
      <div 
        ref={gameContainerRef}
        className="h-[calc(100dvh-48px)] sm:h-[calc(100dvh-64px)] relative"
        style={{
          maxHeight: '-webkit-fill-available',
          ...preventIOSBehavior
        }}
      >
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-black z-10">
            <div className="flex flex-col items-center">
              <div className="w-8 h-8 border-4 border-white/20 border-t-white rounded-full animate-spin mb-3" />
              <span className="text-white/60">Loading game...</span>
            </div>
          </div>
        )}
        <iframe
          ref={iframeRef}
          src={getIframeUrl()}
          className={`w-full h-full ${isNavbarInteracting ? 'pointer-events-none' : ''}`}
          title="Game"
          tabIndex={0}
          style={preventIOSBehavior}
          onLoad={() => setIsLoading(false)}
        />
      </div>
    </div>
  );
}; 