import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../store/slices/authSlice';
import { Toast } from '../components/common/Toast';

export const LogoutPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // Rimuovi il token
    localStorage.removeItem('session_token');
    
    // Dispatch dell'azione di logout
    dispatch(logout());
    
    // Notifica l'utente
    Toast.success('Successfully logged out');
    
    // Reindirizza alla home
    navigate('/');
  }, [navigate, dispatch]);

  return (
    <div className="min-h-screen bg-black text-white flex items-center justify-center">
      <div className="text-center">
        <div className="w-16 h-16 border-4 border-emerald-500/20 border-t-emerald-500 rounded-full animate-spin mx-auto mb-4" />
        <h1 className="text-2xl font-bold mb-2">Logging out...</h1>
        <p className="text-white/60">Please wait while we log you out.</p>
      </div>
    </div>
  );
}; 