import React, { useState, useEffect } from 'react';
import { ParticleField } from '../components/common/ParticleField';
import { useNavigate } from 'react-router-dom';
import Confetti from 'react-confetti';

interface TimeLeft {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const calculateTimeLeft = (targetTimestamp: number): TimeLeft => {
  return {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  };
};

const TimerUnit: React.FC<{ value: number; label: string }> = ({ value, label }) => (
  <div className="flex flex-col items-center">
    <div className="text-3xl md:text-4xl lg:text-6xl font-bold bg-white/10 px-3 md:px-6 py-2 md:py-4 rounded-lg min-w-[80px] md:min-w-[120px] text-center">
      {value.toString().padStart(2, '0')}
    </div>
    <div className="text-white/60 mt-2 text-xs md:text-sm uppercase tracking-wider">{label}</div>
  </div>
);

export const TokenPage: React.FC = () => {
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft(0));
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="min-h-screen bg-black text-white relative overflow-hidden">
      <ParticleField />
      <Confetti
        width={windowSize.width}
        height={windowSize.height}
        numberOfPieces={200}
        recycle={true}
      />
      <div className="relative z-10">
        <div className="max-w-6xl mx-auto px-4 py-8">
          <button
            onClick={() => navigate(-1)}
            className="flex items-center space-x-2 text-white hover:text-emerald-400 transition-colors font-bold text-lg lowercase"
          >
            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
            <span>back</span>
          </button>

          {/* CTA Box at the top */}
          <div className="mt-16 mb-16 bg-emerald-500/20 p-8 rounded-lg text-center border-2 border-emerald-500">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">DEGS Token is Live! 🚀</h2>
            <p className="text-lg text-white/80 mb-6">The fair launch has started! Get your DEGS tokens now on PancakeSwap SpringBoard.</p>
            <a
              href="https://springboard.pancakeswap.finance/base/token/0x0ae8a62393967293ea2a44775110c5a4eb423e8e"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block bg-emerald-400 text-black font-bold py-3 px-6 rounded-lg hover:bg-emerald-500 transition"
            >
              Buy DEGS on SpringBoard
            </a>
          </div>

          {/* Hero Section */}
          <div className="text-center mb-24">
            <h1 className="text-5xl md:text-7xl font-bold mb-8">
              DEGS Token
              <br />
              <span className="text-emerald-400">is Now Live</span>
            </h1>
            <p className="text-xl md:text-2xl text-white/60 max-w-3xl mx-auto">
              Join the revolution in AI-driven game creation
            </p>
          </div>

          {/* Token Utility Sections */}
          <div className="grid md:grid-cols-2 gap-12 md:gap-24 mb-24">
            <div>
              <h2 className="text-3xl font-bold mb-6">revenue share</h2>
              <div className="space-y-6 text-lg text-white/80">
                <p>Hold DEGS to earn your share of platform revenue.</p>
                <p className="text-emerald-400 font-medium">100% of all platform revenue is distributed to holders:</p>
                <ul className="list-disc list-inside space-y-2 text-white/60">
                  <li>In-app purchases</li>
                  <li>Advertisement revenue</li>
                </ul>
              </div>
            </div>
            <div>
              <h2 className="text-3xl font-bold mb-6">platform currency</h2>
              <div className="space-y-6 text-lg text-white/80">
                <p>DEGS is the native token of the ecosystem, powering all platform features:</p>
                <ul className="list-disc list-inside space-y-2 text-white/60">
                  <li>70% revenue to game creators</li>
                  <li>Automated payments</li>
                </ul>
              </div>
            </div>
          </div>

          {/* Market Opportunity */}
          <div className="text-center mb-24">
            <h2 className="text-4xl md:text-5xl font-bold mb-8">
              available now on
              <br />
              <span className="text-emerald-400">PancakeSwap SpringBoard</span>
            </h2>
            <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
              <div className="bg-white/5 p-6 rounded-lg">
                <div className="text-4xl font-bold text-emerald-400 mb-2">90%</div>
                <div className="text-white/60">Fair Launch Allocation</div>
              </div>
              <div className="bg-white/5 p-6 rounded-lg">
                <div className="text-4xl font-bold text-emerald-400 mb-2">100</div>
                <div className="text-white/60">ETH Target Raise</div>
              </div>
            </div>
          </div>

          {/* Community Ownership */}
          <div className="text-center mb-24">
            <h2 className="text-3xl font-bold mb-6">maximum fairness</h2>
            <p className="text-xl text-white/60 max-w-2xl mx-auto">
              No team allocation. No presale.
              <br />
              Automated and secure through SpringBoard.
            </p>
          </div>

          {/* CTA Box at the bottom */}
          <div className="mb-16 bg-emerald-500/20 p-8 rounded-lg text-center border-2 border-emerald-500">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">Get Your DEGS Now!</h2>
            <p className="text-lg text-white/80 mb-6">Join the DEGS ecosystem and be part of the future of AI-driven gaming.</p>
            <a
              href="https://springboard.pancakeswap.finance/base/token/0x0ae8a62393967293ea2a44775110c5a4eb423e8e"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block bg-emerald-400 text-black font-bold py-3 px-6 rounded-lg hover:bg-emerald-500 transition"
            >
              Buy DEGS on SpringBoard
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
