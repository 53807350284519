import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import { EnergyDisplay } from './energy/EnergyDisplay';
import { UsernameModal } from './auth/UsernameModal';
import { GenerationModal } from './games/GenerationModal';
import { AuthMethodModal } from './auth/AuthMethodModal';
import { notificationsApi } from '../services/api';
import { logout } from '../store/slices/authSlice';

export const Navbar: React.FC = () => {
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useSelector((state: RootState) => state.auth);
  const [showUsernameModal, setShowUsernameModal] = useState(false);
  const [showGenerationModal, setShowGenerationModal] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [pendingAddress, setPendingAddress] = useState<string | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchUnreadCount = async () => {
      if (isAuthenticated) {
        try {
          const data = await notificationsApi.getNotifications(1);
          const unread = data.notifications.filter(n => !n.is_read).length;
          setUnreadCount(unread);
        } catch (error) {
          console.error('Failed to fetch notifications:', error);
        }
      }
    };

    fetchUnreadCount();
    // Polling ogni 30 secondi
    const interval = setInterval(fetchUnreadCount, 30000);
    return () => clearInterval(interval);
  }, [isAuthenticated]);

  const handleNewUser = (address: string) => {
    setPendingAddress(address);
    setShowUsernameModal(true);
    setShowAuthModal(false);
  };

  const handleRegistrationComplete = () => {
    setShowUsernameModal(false);
    setPendingAddress(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('session_token');
    dispatch(logout());
    setIsMenuOpen(false);
  };

  const handleCreateGame = () => {
    if (isAuthenticated) {
      setShowGenerationModal(true);
    }
  };

  return (
    <>
      <nav className="fixed w-full z-50 top-0 left-0 px-6 py-2 sm:py-4 bg-black/80 backdrop-blur-sm">
        <div className="max-w-7xl mx-auto flex items-center justify-between">
          <Link to="/" className="text-2xl font-bold">
            degs
          </Link>

          <div className="flex items-center space-x-6">
            {isAuthenticated ? (
              <>
                <EnergyDisplay
                  energies={user?.daily_energies_remaining || 0}
                  stakedAmount={user?.staked_amount || 0}
                />
                
                <div className="relative" ref={menuRef}>
                  <button
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                    className="h-8 flex items-center justify-center text-white hover:text-white/90 focus:outline-none"
                  >
                    <div className="relative h-8 w-8 rounded-lg bg-white/5 flex items-center justify-center hover:bg-white/10 transition-colors">
                      <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                      </svg>
                      {unreadCount > 0 && (
                        <div className="absolute -top-1 -right-1 w-3 h-3 bg-rose-500 rounded-full" />
                      )}
                    </div>
                  </button>

                  {isMenuOpen && user && (
                    <div className="absolute right-0 mt-2 w-48 bg-black/90 backdrop-blur-sm rounded-lg border border-white/10 overflow-hidden">
                      <div className="px-4 py-3 border-b border-white/10">
                        <p className="text-sm font-medium truncate">{user.username}</p>
                        <p className="text-xs text-white/40 truncate">{user.user_address}</p>
                        <Link
                          to="/token"
                          className="mt-2 block hover:bg-white/5 -mx-4 px-4 py-2 transition-colors"
                          onClick={() => setIsMenuOpen(false)}
                        >
                          <div className="space-y-1">
                            <p className="text-xs text-white/60">
                              Energy: <span className="text-white">{user.daily_energies_remaining}</span>
                            </p>
                            <p className="text-xs text-white/60">
                              Staked: <span className="text-white">{user.staked_amount} DEGS</span>
                            </p>
                          </div>
                        </Link>
                      </div>
                      <Link
                        to={`/user/${user.username}`}
                        className="block px-4 py-2 text-sm text-white/60 hover:text-white hover:bg-white/10 transition-colors"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        Profile
                      </Link>
                      <Link
                        to="/library"
                        className="block px-4 py-2 text-sm text-white/60 hover:text-white hover:bg-white/10 transition-colors"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        My Library
                      </Link>
                      <Link
                        to="/notifications"
                        className="relative block px-4 py-2 text-sm text-white/60 hover:text-white hover:bg-white/10 transition-colors"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        Notifications
                        {unreadCount > 0 && (
                          <div className="absolute right-4 top-1/2 -translate-y-1/2 w-2 h-2 bg-rose-500 rounded-full" />
                        )}
                      </Link>
                      <button
                        onClick={handleLogout}
                        className="w-full px-4 py-2 text-left text-sm text-white/60 hover:text-white hover:bg-white/10 transition-colors"
                      >
                        Logout
                      </button>
                    </div>
                  )}
                </div>

                <div className="flex items-center space-x-4">
                  <Link to="/" className="text-white/60 hover:text-white transition-colors">
                    <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                    </svg>
                  </Link>
                  <button
                    onClick={handleCreateGame}
                    className="px-4 py-2 bg-white text-black font-medium rounded-lg hover:bg-white/90 transition-colors"
                  >
                    create
                  </button>
                </div>
              </>
            ) : (
              <div className="flex items-center space-x-4">
                <Link to="/" className="text-white/60 hover:text-white transition-colors">
                  <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                  </svg>
                </Link>
                <button
                  onClick={() => setShowAuthModal(true)}
                  className="px-4 py-2 bg-emerald-500 text-white font-medium rounded-lg hover:bg-emerald-600 transition-colors"
                >
                  connect
                </button>
              </div>
            )}
          </div>
        </div>
      </nav>

      <AuthMethodModal
        isOpen={showAuthModal}
        onClose={() => setShowAuthModal(false)}
        onNewUser={handleNewUser}
      />

      {showUsernameModal && pendingAddress && (
        <UsernameModal
          isOpen={showUsernameModal}
          address={pendingAddress}
          onComplete={handleRegistrationComplete}
        />
      )}

      {showGenerationModal && (
        <GenerationModal
          isOpen={showGenerationModal}
          onClose={() => setShowGenerationModal(false)}
        />
      )}
    </>
  );
}; 