import React, { useState, useEffect } from 'react';
import Confetti from 'react-confetti';

interface FirstPublishModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const FirstPublishModal: React.FC<FirstPublishModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-black/90 p-8 rounded-lg border border-white/10 w-full max-w-2xl">
        <div className="h-full flex flex-col items-center justify-center overflow-hidden">
          <Confetti
            width={windowSize.width}
            height={windowSize.height}
            recycle={false}
            numberOfPieces={500}
          />
          
          <div className="w-full max-w-lg text-center px-4">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">
              you are a Game Studio now
            </h2>
            
            <div className="space-y-4 text-base md:text-lg text-white/80">
              <p>
                Your game is now live on our Home page, ready to be discovered by the DEGS community.
              </p>
              
              <div className="bg-white/5 p-4 md:p-6 rounded-lg space-y-4">
                <div className="flex items-start space-x-3">
                  <svg className="w-6 h-6 text-emerald-400 mt-1 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
                  </svg>
                  <p className="text-sm md:text-base text-left">
                    Share your game's public link with anyone - they can start playing instantly, no registration or downloads needed.
                  </p>
                </div>
                
                <div className="flex items-start space-x-3">
                  <svg className="w-6 h-6 text-emerald-400 mt-1 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                  </svg>
                  <p className="text-sm md:text-base text-left">
                    As players engage with your game and give it likes, it will climb our leaderboards and could become the next trending hit.
                  </p>
                </div>
                
                <div className="flex items-start space-x-3">
                  <svg className="w-6 h-6 text-emerald-400 mt-1 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <p className="text-sm md:text-base text-left">
                    Stay tuned for our upcoming revenue sharing program - soon you'll earn from your game's success on the platform.
                  </p>
                </div>
              </div>

              <p className="text-base md:text-lg font-medium">
                Ready to kill old game studios with us?
              </p>
            </div>
            
            <button
              onClick={onClose}
              className="mt-6 px-6 py-3 bg-emerald-500 text-white font-medium rounded-lg hover:bg-emerald-600 transition-colors w-full"
            >
              Let's Fucking Go! 🚀
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}; 