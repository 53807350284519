import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUser, setLoading, logout } from '../../store/slices/authSlice';
import { ApiError } from '../../types/auth';
import { Toast } from '../common/Toast';
import { userApi } from '../../services/api';

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const dispatch = useDispatch();

  // Effect for initial session check
  useEffect(() => {
    const checkSession = async () => {
      const token = localStorage.getItem('session_token');
      
      try {
        if (!token) {
          dispatch(setLoading(false));
          return;
        }

        const { user } = await userApi.getMe();
        
        // Settiamo l'utente nello store SOLO se ha uno username
        if (user.username) {
          dispatch(setUser(user));
        }
      } catch (err) {
        const apiError = err as ApiError;
        console.error('Session validation failed:', apiError.message);
        localStorage.removeItem('session_token');
        dispatch(logout());
      } finally {
        dispatch(setLoading(false));
      }
    };

    checkSession();
  }, [dispatch]);

  // Effect for periodic user data update
  useEffect(() => {
    const token = localStorage.getItem('session_token');
    if (!token) return;

    const updateUserData = async () => {
      try {
        const { user } = await userApi.getMe();
        // Anche qui, settiamo l'utente SOLO se ha uno username
        if (user.username) {
          dispatch(setUser(user));
        }
      } catch (err) {
        console.error('Failed to update user data:', err);
      }
    };

    // Set up polling interval (every minute)
    const interval = setInterval(updateUserData, 60000);

    // Clean up interval on unmount
    return () => clearInterval(interval);
  }, [dispatch]);

  // Listen for MetaMask account changes
  useEffect(() => {
    if (!window.ethereum) {
      return;
    }

    const handleAccountsChanged = async (accounts: string[]) => {
      if (accounts.length === 0) {
        // User disconnected their wallet
        localStorage.removeItem('session_token');
        dispatch(logout());
        return;
      }

      const token = localStorage.getItem('session_token');
      if (!token) {
        return;
      }

      try {
        dispatch(setLoading(true));
        const { user } = await userApi.getMe();
        
        // Check if the connected address matches the user's address
        if (user.user_address.toLowerCase() !== accounts[0].toLowerCase()) {
          throw new Error('Address mismatch');
        }

        // Anche qui, settiamo l'utente SOLO se ha uno username
        if (user.username) {
          dispatch(setUser(user));
        }
      } catch (err) {
        const apiError = err as ApiError;
        console.error('Account validation failed:', apiError.message);
        localStorage.removeItem('session_token');
        dispatch(logout());
        Toast.error('Please reconnect your wallet');
      } finally {
        dispatch(setLoading(false));
      }
    };

    const ethereum = window.ethereum;
    ethereum.on('accountsChanged', handleAccountsChanged);

    return () => {
      ethereum.removeListener('accountsChanged', handleAccountsChanged);
    };
  }, [dispatch]);

  return <>{children}</>;
}; 