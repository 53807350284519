import React from 'react';

export const LoadingScreen: React.FC = () => {
  return (
    <div className="fixed inset-0 bg-black flex items-center justify-center">
      <div className="flex flex-col items-center">
        <div className="w-8 h-8 border-4 border-white/20 border-t-white rounded-full animate-spin mb-3" />
        <p className="text-white/60">Loading app...</p>
      </div>
    </div>
  );
}; 